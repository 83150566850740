import clsx from "clsx";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { PackageDetails } from "./OtherPackages";
import * as classes from "./styles.module.css";
import { prices } from "./prices";

const PackagesHome: React.FC = () => {
  return (
    <div>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Link to="/chardham-by-helicopter">
            <div
              className={clsx(
                "w-[350px] h-[100%] rounded overflow-hidden shadow-lg bg-white flex flex-col hover:shadow-none transition duration-300 border border-gray-200",
                classes.slide
              )}
            >
              <StaticImage
                src="../../images/chardham-new.jpeg"
                alt="Chardham"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <PackageDetails
                name="Chardham 5N/6D Package"
                price={prices.charDham5N6D.price}
                discountedPrice={prices.charDham5N6D.discountedPrice}
                duration="5 Night 6 Days"
                route="Dehradun - Yamunotri - Gangotri - Kedarnath - Badrinath - Dehradun"
                priceDetails="Price per yatri"
              />
            </div>
          </Link>
          <Link to="/chardham-by-helicopter/packages/do-dham">
            <div
              className={clsx(
                "w-[350px] h-[100%] rounded overflow-hidden shadow-lg bg-white flex flex-col hover:shadow-none transition duration-300 border border-gray-200",
                classes.slide
              )}
            >
              <StaticImage
                src="../../images/do-dham.png"
                alt="Do Dham"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <PackageDetails
                name="Do Dham 1D Package"
                price={prices.doDham.price}
                discountedPrice={prices.doDham.discountedPrice}
                duration="1 Day (Same day return)"
                route="Dehradun - Kedarnath - Badrinath - Dehradun"
                priceDetails="Price per yatri"
              />
            </div>
          </Link>

          <Link to="/chardham-by-helicopter/packages/chardham-1n-2d">
            <div
              className={clsx(
                "w-[350px] h-[100%] rounded overflow-hidden shadow-lg bg-white flex flex-col hover:shadow-none transition duration-300 border border-gray-200",
                classes.slide
              )}
            >
              <StaticImage
                src="../../images/chardham-new.jpeg"
                alt="Chardham"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <PackageDetails
                name="Chardham 1N/2D Package"
                price={prices.charDham1N2D.price}
                discountedPrice={prices.charDham1N2D.discountedPrice}
                duration="1 Night 2 Days"
                route="Dehradun - Yamunotri - Gangotri - Kedarnath - Badrinath"
                priceDetails="Price per charter 6 yatri"
              />
            </div>
          </Link>

          <Link to="/chardham-by-helicopter/packages/ek-dham">
            <div
              className={clsx(
                "w-[350px] h-[100%] rounded overflow-hidden shadow-lg bg-white flex flex-col hover:shadow-none transition duration-300 border border-gray-200",
                classes.slide
              )}
            >
              <StaticImage
                src="../../images/ekdham-new.jpeg"
                alt="Kedarnath"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <PackageDetails
                name="Ek Dham 1D Package"
                price={prices.ekDham.price}
                discountedPrice={prices.ekDham.discountedPrice}
                duration="1 Day (Same day return)"
                route="Dehradun - Kedarnath - Dehradun"
                priceDetails="Price per charter 6 yatris"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PackagesHome;
