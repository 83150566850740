import React from "react";
import { Heading, Layout } from "~/components";
import { chardhamLinks } from "~/constants/links";
import PackagesHome from "~/views/ChardhamHome/PackagesHome";

const BlogListing: React.FC = () => {
  return (
    <Layout links={chardhamLinks}>
      <Heading className="text-center" type="h2">
        Packages
      </Heading>
      <PackagesHome />
    </Layout>
  );
};

export default BlogListing;
